import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	intro: {
		textAlign: 'left',
		// marginTop: '6rem',
		marginBottom: '-4rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '2rem',
			marginBottom: '0rem',
		},
	},
	firstLine: {
		color: theme.workwaveBlue,
		fontSize: '2.25rem',
		fontWeight: 700,
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.8rem',
		},
	},
	secondLine: {
		color: '#3FA529',
	},
	paragraph: {
		lineHeight: '32px',
		marginTop: '1rem',
		fontWeight: 400,
		fontFamily: ['Roboto', 'sans-serif'].join(),
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.25rem',
		},
	},
}));

export const TemplatesIntro = ({ intro }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			direction='row'
			justifyContent='flex-start'
			alignItems='center'
			className={classes.intro}>
			<Grid item xs={12} style={{ zIndex: 1000 }}>
				<PortableText
					content={intro}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								color='primary'
								className={classes.firstLine}
								style={{ lineHeight: '1.0' }}>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
							<span className={classes.secondLine}>{children}</span>
						),
						normal: ({ children }) => (
							<Typography variant='body1' className={classes.paragraph}>
								{children}
							</Typography>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
};
